//
//
//
//
//
//

import DomesticTicketAirlineSelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/domestic-ticket-airline-selector/1.0.0/index.vue";
export default {
  data() {
    return {
      code: null,
    };
  },
  methods: {},
  watch: {
    code(val) {
      val ? this.$emit("input", val.airlineCode) : this.$emit("input", "");
    }
  },

  components: { DomesticTicketAirlineSelector }
};
